import React, { useState } from 'react'
import { Form, Input, Checkbox, message } from 'antd'
import useGMT from '../../../hooks/useGTM'
import { auth0Roles, strapiURL } from '../../../config'
import { setProfileData, setProducts } from '../../../redux/actions/user'
import axios from 'axios'
import cookie from 'react-cookies'
import { useDispatch } from 'react-redux'
import { navigate } from 'gatsby'

export default function CandidateForm({ subscription, promoCode }) {
  const { pushCandidateCheckoutEvent, pushCandidateSelectItemEvent } = useGMT()
  const [candidateForm] = Form.useForm()
  const dispatch = useDispatch()

  const [submitLoading, setSubmitLoading] = useState(false)

  async function signUp(values) {
    let preCheckData = {
      email: (values.email || '').toLowerCase(),
    }

    const result = await axios.post(
      strapiURL + '/auth0/checkEmail',
      preCheckData
    )

    const resultEmail = result.data

    if (resultEmail.format === false) {
      message.error('Email format is invalid.')
      return
    }

    if (resultEmail.mxLookup === false) {
      message.error('Cannot verify email domain')
      return
    }

    if (resultEmail.smtp === false) {
      message.error('Cannot validate email smtp setting')
      return
    }

    pushCandidateCheckoutEvent(subscription?.price?.priceId, subscription)

    setSubmitLoading(true)
    let data = {
      email: values.email,
      password: values.password,
      last_login: new Date(),
      roleId: auth0Roles.Member.id,
      firstName: values.firstName,
      lastName: values.lastName,
      name: values.firstName + ' ' + values.lastName,
      is_newsletter_subscriber: values?.is_newsletter_subscriber || false,
    }

    axios
      .post(strapiURL + '/auth0/signup', data)
      .then(res => {
        const userData = res.data
        const responseRole = userData.role.id
        let profile = userData?.profile
        delete userData.profile

        cookie.save('userRole', responseRole, { path: '/', maxAge: 259200 })
        cookie.save('user', userData, { path: '/', maxAge: 259200 })

        dispatch(setProfileData(profile))

        if (!subscription && typeof window !== 'undefined') {
          window.location.href = '/member-dashboard'
        } else if (!userData.isCheckoutRequired) {
          alert('You already have a subscription with us.')

          // Retrive updated subscription
          axios
            .get(
              strapiURL +
                `/employers/${userData?.user?.id}/subscriptionsAndCredits`
            )
            .then(res => {
              dispatch(setProducts(res?.data))
              navigate('/member-dashboard/', {
                state: { firstRegistration: true },
              })
            })
            .catch(err => {
              console.log(
                'Something wrong while retrive subscriptionsAndCredits.'
              )
            })
        } else {
          if (typeof window !== 'undefined') {
            let _checkoutPrices = []
            _checkoutPrices.push({
              price: subscription?.price?.priceId,
              quantity: 1,
            })

            localStorage.setItem(
              'can-subscription',
              subscription?.price?.priceId
            )

            axios
              .post(strapiURL + '/stripe/session/checkout', {
                line_items: _checkoutPrices,
                success_url:
                  window.location.origin +
                  '/thankyou/?success=true&session_id={CHECKOUT_SESSION_ID}&first_registration=true',
                cancel_url: window.location.origin,
                customerEmail: values?.email || '',
                mode: 'subscription',
                clientId: cookie.load('_ga')?.substring(6),
                promoCode: promoCode,
                sessionId: cookie
                  .load(
                    process.env.GATSBY_GOOGLE_ANALYTICS_MEASUREMENT_ID?.replace(
                      'G-',
                      '_ga_'
                    )
                  )
                  ?.split('.')[2],
              })
              .then(res => {
                if (typeof window !== 'undefined') {
                  window.history.replaceState(
                    '',
                    '',
                    `${window.location.origin}/thankyou/`
                  )
                  window.location.href = res?.data?.url
                }
              })
              .catch(err => {
                message.error('Something wrong while checkout.')
              })
          }
        }
      })
      .catch(err => {
        message.error(err?.response?.data?.message[0].error)
        setSubmitLoading(false)
      })
  }

  return (
    <div>
      <Form
        form={candidateForm}
        name="candidate-form"
        layout="vertical"
        onFinish={signUp}
      >
        <Form.Item
          name="email"
          label="Email"
          className="theme-form-item-update font-arial"
          rules={[
            {
              type: 'email',
              message: 'Invalid Email.',
            },
            {
              required: true,
              message: 'Required.',
            },
          ]}
        >
          <Input type="email" size="large" />
        </Form.Item>
        <Form.Item
          name="firstName"
          label="First name"
          className="theme-form-item-update font-arial"
          rules={[
            {
              required: true,
              message: 'Required.',
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Last name"
          className="theme-form-item-update font-arial"
          rules={[
            {
              required: true,
              message: 'Required.',
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          className="theme-form-item-password-update font-arial"
          rules={[
            {
              required: true,
              message: 'Required.',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirm"
          dependencies={['password']}
          label="Confirm password"
          className="theme-form-item-password-update font-arial"
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!')
                )
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          valuePropName="checked"
          name="agree"
          className="theme-form-item-update mb-2"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(new Error('Required')),
            },
          ]}
        >
          <Checkbox className="font-arial">
            {' '}
            I have read and agree to the MedReps{' '}
            <a href="/privacy-policy" target="_blank">
              Terms & Conditions
            </a>
          </Checkbox>
        </Form.Item>
        <p className="pt-0 font-arial text-last mb-4">
          By creating a MedReps account, you agree to receive marketing
          communications, updates, and relevant information about our services
          and offers. We are committed to respecting your preferences, and you
          can unsubscribe from these communications at any time by following the
          link provided in our emails or contacting our support team. Your
          privacy is important to us, and we ensure that your information is
          safe and handled in accordance with{' '}
          <a href="/privacy-policy" target="_blank">
            MedReps' privacy policy
          </a>
          .
        </p>
        <Form.Item
          className="theme-form-item-update mb-4"
          name="is_newsletter_subscriber"
          valuePropName="checked"
        >
          <Checkbox name="subscribe" className="font-arial">
            Subscribe to The Brief, MedReps' newsletter featuring latest medical
            sales career and industry insights
          </Checkbox>
        </Form.Item>
        <p className="pt-0 font-arial text-last mb-6">
          By subscribing to our mailing list, you agree to receive marketing
          communications, updates, and relevant information about our services
          and offers. We are committed to respecting your preferences, and you
          can unsubscribe from these communications at any time by following the
          link provided in our emails or contacting our support team. Your
          privacy is important to us, and we ensure that your information is
          safe and handled in accordance with{' '}
          <a href="/privacy-policy" target="_blank">
            MedReps' privacy policy
          </a>
          .
        </p>
        <button
          type="submit"
          className="bg-[#c6004c] w-full max-w-[150px] py-4 text-white font-arial font-bold mb-0"
        >
          {submitLoading ? 'Loading...' : 'GET HIRED NOW'}
        </button>
      </Form>
    </div>
  )
}
