import React, { useEffect, useState } from 'react'
import Wrapper from '../../components/wrapper'
import globalData from '../../../globalData.json'
import CandidateForm from '../../components/pageComponents/candidateForm'
import { DiamondIcon } from '../../components/icons'

const OPTION_LIST = [
  {
    title: 'Unlimited Access to Job Listings',
    desc: 'With MedReps, unlock a curated list of medical sales jobs that align with your goals, with fresh opportunities added daily.',
  },
  {
    title: 'Insider Career Advice',
    desc: 'Gain a competitive edge with expert tips and industry-specific guidance on interviews, resume building, and earning potential.',
  },
  {
    title: 'Flexible, No-Commitment Plan',
    desc: 'Not sure if you want to commit for the long haul? The monthly plan lets you explore all MedReps has to offer with the option to cancel anytime.',
  },
]

export default function MonthlyJoinNow({ location }) {
  const [subscription, setSubscription] = useState(null)
  const params = new URLSearchParams(location.search)

  useEffect(() => {
    let _prices = globalData?.CandidateCatalog?.prices?.length
      ? globalData?.CandidateCatalog?.prices
      : []
    const monthlyPrice = _prices.find(item => item.name === 'Monthly')
    if (monthlyPrice) {
      setSubscription(monthlyPrice)
    }
  }, [])

  return (
    <Wrapper
      location={location}
      title="Biotech Sales | MedReps"
      description="Access the latest salary trends and insights in biotech sales, with valuable data and analysis to help you make informed decisions about your medical sales career."
    >
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 my-8 items-center">
          <div className="p-8 md:p-0">
            <h4 className="text-[#6d1e36] leading-[1.25] text-[52px] font-bold font-georgia">
              Ready to break into <br /> medical sales?
            </h4>
            <img src="/images/join-now-monthly.png" />
            <p className="leading-[1.25] font-arial text-xl mt-10">
              Our <strong className="text-[#c6004c]">Monthly Membership</strong>{' '}
              gives you the flexibility and powerful resources you need-without
              a long-term commitment
            </p>
          </div>
          <div className="p-8">
            <CandidateForm
              subscription={subscription}
              promoCode={params.get('utm_promocode')}
            />
          </div>
        </div>
        <div className="gap-4 block md:grid grid-cols-3 mb-4">
          {OPTION_LIST.map(item => (
            <div className="bg-white shadow-profileCard rounded-lg p-8 m-4">
              <div className="w-[32px] mb-4">
                <DiamondIcon fillColor={'#6d1e36'} />
              </div>
              <h1 className="m-0 sm:mb-3 font-arial text-2xl font-normal">
                {item.title}
              </h1>
              <p className="font-arial text-sm md:text-base mb-0">
                {item.desc}
              </p>
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  )
}
